import React from "react";

const About = () => {
  const anchor = "About";

  return (
    <div className="bg-grey section-ptb" id={anchor}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="about-title">
              <h2>
                About us
                <span> - Our Origin Story</span>
              </h2>
              <h4>
                Every superhero has an origin story and the same is true for super teams
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 order-2 order-lg-1">
            <div className="about-content-inner">
              <div className="about-text">
                <p>
                  We began our journey in 2018 as a small team of seasoned professionals on a mission
                  to help our largest client at the time rediscover the art of delivering quality
                  software on time and on budget. Sounds simple, we know, but simple is frequently
                  lost in large enterprises when multiple stakeholders are involved.
                </p>
                <p>
                  As it usually happens, success brings more work and we've been lucky enough to be
                  recognised by our clients, so that our team grew six-fold in two years. We took on
                  more projects and built dedicated teams for Cloud Applications Development, Cloud
                  Migration and Business Applications with focus on Tableau. We now manage and
                  support products and applications that operate in the United Kingdom as well as in
                  France, Germany, Netherlands, Singapore, Australia, New Zealand and the USA.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 order-2 order-lg-1">
            <div className="about-content-inner">
              <div className="about-text">
                <p>
                  Our headquarters is in the beautiful seaside town of Varna in Bulgaria but our team
                  members are mostly remote and work from various places such as the town of Plovdiv
                  and the capital Sofia. Our sales and operations office is in the town of Reading
                  in the United Kingdom. We pride ourselves on being able to make remote teams work
                  which also enables us to find the best talent without being limited to specific
                  locations.
                </p>
                <p>
                  No company overview would be complete without dropping some core values. Ours are
                  rooted in the entrepreneurial spirit, giving employees every opportunity to be
                  brilliant and have true ownership of the products they build. We embrace the
                  adventure on a daily basis and invite those who refuse to accept good enough to
                  join us for the ride.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
